import React, {useState} from 'react'
import Button from '@material-ui/core/Button';
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { cookieBannerHolder } from './CookieBanner.module.scss'
import { Link } from 'gatsby';
import {motion, AnimatePresence} from 'framer-motion'
import Cookies from 'universal-cookie';


const variants = {
  pre: {
    y:'100%'
  },
  visible: {
    y:'0',
    transition: {
      delay:3,
      duration:0.5,
      type:'tween'
    }
  },
  post: {
    y:'100%',
    transition: {
      duration:0.4,
      type:'tween'
    }
  }

}

const theme = createTheme({
  typography: {
    // In Chinese and Japanese the characters are usually larger,
    // so a smaller fontsize may be appropriate.
    htmlFontSize: 10,
    fontFamily: ['Switzer']
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#44A572',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
  },
  shape: {
    borderRadius:0
  },
  shadows: ['none'],
  overrides: {
    MuiFilledInput: {
      root: {
        backgroundColor:'#ffffff',
        marginBottom: '1.5rem',
        '&$focused': {
          backgroundColor:'#ffffff'
        },
        '&:hover': {
          backgroundColor:'#fafafa'
        },
        '&$error': {
          marginBottom:'0.5rem'
        },
        helperText: {
          marginBottom:'1rem'
        }
      }
    },
    MuiButton: {
      containedSizeLarge: {
        paddingLeft: '3rem',
        paddingRight: '3rem',
        fontSize:'1.8rem'
      }
    },
    MuiFormHelperText: {
      root: {
        marginBottom:'1rem'
      }
    }
  }
});

export default function CookieBanner() {
  const cookies = new Cookies();
  const acceptCookies = cookies.get('acceptCookies')

  const [showBanner, setShowBanner] = useState(!acceptCookies)


  const accept = () => {
    var dt = new Date();
    dt.setDate(dt.getDate() + 600); 
    cookies.set('acceptCookies', 'true', { path: '/', expires: dt });
    setShowBanner(false)
  }


  return (
    <AnimatePresence>
    { showBanner && <motion.div className={cookieBannerHolder} variants={variants} initial="pre" animate="visible" exit="post">
      <p>This site uses cookies. Read our <Link to="/privacy">Privacy policy</Link> to find out more</p>
      <ThemeProvider theme={theme}>
      <Button variant="contained" size="small" color="primary" disableElevation onClick={()=>accept()}>Accept</Button>
      </ThemeProvider>
    </motion.div>
    }
    </AnimatePresence>
  )
}
