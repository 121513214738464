import React, {useState, useEffect, useContext}from "react"
import { navigate } from "gatsby"

import useWindowSize from '../../hooks/useWindowSize'
import useWheelNav from '../../hooks/useWheelNav'

import "../../styles/reset.css"
import "../../styles/styles.scss"

import { mainHolder, headerHolder } from "./Layout.module.scss"

import Header from "./Header"
import Nav from "./Nav"
import Footer from "./Footer"

import { motion, AnimatePresence } from 'framer-motion'
import SectionNav from "../components/SectionNav"

import {SectionNavContext} from '../utilities/SiteContexts'
import CookieBanner from "../components/CookieBanner"

import useScrollY from '../../hooks/useScrollY'

const getNext = (sectionNav) => {
  const current = sectionNav.findIndex(section => section.active === true)
  const next = ( sectionNav.length > current ) ? sectionNav[current+1] : null

  return next?.to
}

const getPrev = (sectionNav) => {
  const current = sectionNav.findIndex(section => section.active === true)
  const prev = ( current > 0 ) ? sectionNav[current-1] : null

  return prev?.to
}



const Layout = ({ children, location, ...props}) => {
  const [isHome, setIsHome] = useState(children?.props?.path==='/')
  const [isDark, setIsDark] = useState(false)
  const scrollY = useScrollY()
  const [isScrolled, setIsScrolled] = useState(false)
  const [isSmallScreen, setIsSmallScreen] = useState(false)

  const [sectionNav] = useContext(SectionNavContext)
  const size = useWindowSize()
  const [wheelNav, setWheelNav] = useWheelNav()


  useEffect(()=>{
    const breakpoint = 700

    if(size.width < breakpoint)
    setIsSmallScreen(true)

    if(size.width > breakpoint)
    setIsSmallScreen(false)

    if(((size.width / size.height) > 1.7) && (size.height < 600))
    setIsSmallScreen(true)

  }, [size, setIsSmallScreen])



  useEffect(()=>{
    const darkPages = ['our-services','about/achievements']
    let dark = false
    
    darkPages.forEach(page => {

      if(children?.props?.path.startsWith(`/${page}`))
      dark = true

    });
    setWheelNav(undefined)
    setIsHome(children?.props?.path==='/')
    setIsDark(dark)
  }, [children, setIsHome, setIsDark, setWheelNav])


  useEffect(()=>{
   if(sectionNav?.length>1) {
      if(wheelNav==='next') {
        setWheelNav(undefined)
        let next = getNext(sectionNav)
        if(next)
          navigate(next)
      }
      if(wheelNav==='prev') {
        setWheelNav(undefined)
        let prev = getPrev(sectionNav)
        if(prev)
          navigate(prev)
      }
    }
  }, [wheelNav, sectionNav, navigate, setWheelNav])


  useEffect(()=>{
    setIsScrolled(scrollY > 100)
  },[scrollY])


  return (
    <div className={mainHolder}>

      <header className={`${headerHolder} ${isScrolled ? 'headerScrolled' : ''} ${isDark ? 'headerDark' : ''}`} >
          <Header isHome={isHome} isDark={isDark} />
          <Nav isSmallScreen={isSmallScreen} isDark={isDark} />
      </header>
      <AnimatePresence
        exitBeforeEnter>
        <motion.main key={children?.props?.path}>
          {children}
        </motion.main>
      </AnimatePresence>
      <SectionNav isDark={isDark} />

      <Footer />
      <CookieBanner />

    </div>
  )
}

export default Layout
