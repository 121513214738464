import { Link } from 'gatsby'
import React from 'react'
import {footerHolder} from './Footer.module.scss'

export default function Footer() {

  const year = new Date().getFullYear()

  return (
    <footer className={footerHolder}>
       <div className="copyright">
          &copy; {year} - Carbon Climate Solutions Ltd 
        </div>
        <div className="credit">
        Site by <a href="mailto:ben@lookupstudios.co.uk">Look Up Studios</a>. Branding by <a href="https://www.instagram.com/cecestudio.ldn">Cece Studios</a>
        </div>
        <div className="privacy">
        <Link to="/privacy" title="Privacy & Terms">Privacy & Terms</Link>
        </div>
    </footer>
  )
}
