import React, {useContext} from 'react'
import {SectionNavContext} from '../utilities/SiteContexts'
import {sectionNavHolder} from './SectionNav.module.scss'
import { Link } from 'gatsby'

export default function SectionNav({isDark}) {
  const [sectionNav] = useContext(SectionNavContext)
  let prev = {}
  let next = {}
  
  if(sectionNav?.length>0) {
    const activeIndex = sectionNav.findIndex(section => section.active === true)
    if(sectionNav[activeIndex-1]) {
      prev = sectionNav[activeIndex-1]
    }
    if(sectionNav[activeIndex+1]) {
      next = sectionNav[activeIndex+1]
    }
  }

  return (
    <>
      { sectionNav?.length > 0 && <ul className={sectionNavHolder} style={{color: `${isDark ? 'white' : 'inherit'}`}}>

        { prev.to ?
          <li className={`${isDark ? 'dark' : ''}`}><Link to={prev.to} alt={prev.title}>Previous section</Link></li> :
          <li className={`disabled previous ${isDark ? 'dark' : ''}`}>No previous section</li>
        }

        {
          sectionNav.map((link, index)=>{
            return <li key={index} className={`${link.active ? 'activeSection' : ''} ${isDark ? 'dark' : ''}`}><Link to={link.to} alt={link.title}>{link.title}</Link></li>
          })
        }
        
        {
          next.to ? 
            <li className={`${isDark ? 'dark' : ''}`}><Link to={next.to} alt={next.title}>Next section</Link></li> :
            <li className={`disabled next ${isDark ? 'dark' : ''}`}>No next section</li>
        }
      </ul> }
    </>
  )
}


