// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-achievements-js": () => import("./../../../src/pages/about/achievements.js" /* webpackChunkName: "component---src-pages-about-achievements-js" */),
  "component---src-pages-about-benefits-js": () => import("./../../../src/pages/about/benefits.js" /* webpackChunkName: "component---src-pages-about-benefits-js" */),
  "component---src-pages-about-contact-js": () => import("./../../../src/pages/about/contact.js" /* webpackChunkName: "component---src-pages-about-contact-js" */),
  "component---src-pages-about-expertise-js": () => import("./../../../src/pages/about/expertise.js" /* webpackChunkName: "component---src-pages-about-expertise-js" */),
  "component---src-pages-about-video-js": () => import("./../../../src/pages/about/video.js" /* webpackChunkName: "component---src-pages-about-video-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-our-partners-and-clients-js": () => import("./../../../src/pages/our-partners-and-clients.js" /* webpackChunkName: "component---src-pages-our-partners-and-clients-js" */),
  "component---src-pages-our-projects-js": () => import("./../../../src/pages/our-projects.js" /* webpackChunkName: "component---src-pages-our-projects-js" */),
  "component---src-pages-our-services-js": () => import("./../../../src/pages/our-services.js" /* webpackChunkName: "component---src-pages-our-services-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */)
}

