import React from 'react'
import {headerHolder} from './Header.module.scss'
import { motion } from 'framer-motion'
import { Link } from 'gatsby'

const headerVariants = {
  pre: {
    opacity:0
  },
  vis: {
    opacity:1,
    transition: {
      delay:1.5
    }
  },
  post: {
    opacity:0
  }
}

export default function Header({isHome, isDark}) {
  return (
    <Link to="/" title="Carbon Climate Solutions homepage">
    <motion.div variants={headerVariants} initial="pre" animate={`${isHome ? 'pre' : 'vis'}`}className={headerHolder} style={{color: `${isDark ? 'white' : 'inherit'}`}}>
      <svg width="63" height="47" viewBox="0 0 63 47" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M46.0002 23.9999C46.0002 36.7028 35.7022 47 23.0001 47C10.298 47 0 36.7028 0 23.9999C0 11.2978 10.298 1 23.0001 1C35.7022 1 46.0002 11.2978 46.0002 23.9999Z" fill="#44A572"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M62.0817 16.6382C62.0817 8.11363 55.1608 1.19287 46.6362 1.19287C38.1114 1.19287 31.1904 8.11363 31.1904 16.6382C31.1904 25.1626 38.1114 32.0834 46.6362 32.0834C55.1608 32.0834 62.0817 25.1626 62.0817 16.6382Z" stroke="currentColor" strokeWidth="1" strokeMiterlimit="10"/>
      </svg>
      <h1 style={{color: `${isDark ? 'white' : 'inherit'}`}}>Carbon Climate Solutions.</h1>
    </motion.div> 
    </Link>
  )
}
