import React, { useState, useMemo } from "react"

// Create the menu context
export const MenuContext = React.createContext(false)
export const SectionNavContext = React.createContext([])

export function ContextsProvider( { children}) {

  const [navOpen, setNav] = useState(false)
  const [sectionNav, setSectionNav] = useState([])

  const menuMemo = useMemo(()=> ([navOpen, setNav]), [navOpen, setNav])
  const sectionNavMemo = useMemo(()=> ([sectionNav, setSectionNav]), [sectionNav, setSectionNav])

  return (
    <MenuContext.Provider value={menuMemo}>
      <SectionNavContext.Provider value={sectionNavMemo}>
        {children}
      </SectionNavContext.Provider>
    </MenuContext.Provider>
  )
}

