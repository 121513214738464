import React, {useContext} from 'react'

import { MenuContext } from '../utilities/SiteContexts'

import { Link } from 'gatsby'

import '../../styles/hamburgers.min.css'

import {navHolder} from './Nav.module.scss'

import { AnimatePresence, motion } from 'framer-motion'


const variants = {
  pre: {
    opacity:0,
    x:'2rem',
    transition: {
      staggerChildren:0.3,
      when: 'beforeChildren'
    }
  },
  vis: {
    opacity:1,
    x:0,
    transition: {
      staggerChildren:0.05
    }
  }, 
  post: {
    opacity:0,
    x:'2rem',
    transition: {
      staggerChildren:0.05,
      when: 'afterChildren'
    }
  },
}

export default function MainNav({isSmallScreen, isDark}) {
  const [navOpen, setNavOpen] = useContext(MenuContext)


  // useEffect(()=>{
  //   setNavOpen(!isSmallScreen)
  // },[isSmallScreen, setNavOpen])

  return (
    <div className={navHolder} >
        { isSmallScreen &&
        <div className={`burger ${isDark ? 'dark' : ''}`}>
          <button
            className={`hamburger hamburger--slider
            ${navOpen ? 'is-active' : ''}`}
            type="button"
            onClick={()=>setNavOpen(!navOpen)}
            >
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
        }

        <AnimatePresence >
        {
        (navOpen || !isSmallScreen) && 
          <motion.ul 
            key="linksHolder"
            className={`linksHolder ${isSmallScreen && 'smallScreenNavHolder'} ${isDark && 'dark'}`}
            onClick={()=>setNavOpen(false)}
            variants={variants}
            initial="pre"
            animate="vis"
            exit="post"
            onKeyDown={(e)=> {
              if (e.code==='Escape')
              setNavOpen(false)
            }}
            style={{color: `${isDark ? 'white' : 'inherit'}`}}
            >
              <motion.li variants={variants}><Link to="/" title="Back to the Homepage" activeClassName="active">Home</Link></motion.li>
              <motion.li variants={variants}><Link to="/our-services" title="Our Services" activeClassName="active">Our Services</Link></motion.li>
              <motion.li variants={variants}><Link to="/our-projects" title="Our Projects" activeClassName="active">Our Projects</Link></motion.li>
              <motion.li variants={variants}><Link to="/our-partners-and-clients" title="Our Partners & Clients" activeClassName="active">Our Partners & Clients</Link></motion.li>
              <motion.li variants={variants} className="contact"><Link to="/contact" title="Contact" activeClassName="activeContact" >Contact</Link></motion.li>
          </motion.ul>
        }
        </AnimatePresence>
    </div>
  )
}
