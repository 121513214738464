import { useState, useEffect, useRef } from 'react';


const calcPosition = (scrollY, windowHeight, bodyHeight) => {
  let position = 'middle'

  if(scrollY === 0)
  position = 'top'

  if(bodyHeight - (windowHeight + scrollY) === 0)
  position = 'bottom'


  return position
}




export default function useWheelNav() {
  const [wheelNav, setWheelNav] = useState(0)
  const [touchDelta, setTouchDelta] = useState(0)
  const previousTouchPosition = useRef(undefined)

  useEffect(()=> {

    function wheeled(e) {
      const scrollY = window.scrollY
      const windowHeight = window.innerHeight
      const bodyHeight = document.body.clientHeight
      const delta = e.deltaY
    
      const position = calcPosition(scrollY, windowHeight, bodyHeight)

      if((position === 'top')||(position==='bottom')) {
        if(delta >= 10)
        setWheelNav('next')

        if(delta <= -10)
        setWheelNav('prev')
      }
    }

    function touchMove(e) {
      const scrollY = window.scrollY
      const windowHeight = window.innerHeight
      const bodyHeight = document.body.clientHeight
      const position = calcPosition(scrollY, windowHeight, bodyHeight)


      if((position === 'top')||(position==='bottom')) {
        if(previousTouchPosition.current === undefined)
          previousTouchPosition.current = e.changedTouches[0].pageY

        const delta = e.changedTouches[0].pageY - previousTouchPosition.current
        setTouchDelta(prevDelta =>   delta + prevDelta)
        previousTouchPosition.current = e.changedTouches[0].pageY
      }

    }

    function touchEnd() {
        previousTouchPosition.current = undefined
        setTouchDelta(0)
    }

    if (typeof window !== undefined) {
      window.addEventListener('wheel', wheeled)
      window.addEventListener('touchmove', touchMove)
      window.addEventListener('touchend', touchEnd)
    }

    return () => {

      if (typeof window !== undefined) {
      window.removeEventListener('wheel', wheeled)
      window.removeEventListener('touchmove', touchMove)
      window.removeEventListener('touchend', touchEnd)
      }
    }

  },[])

  useEffect(() => {
    
    if(touchDelta > 30) {
      setWheelNav('prev')
    }

    if(touchDelta < -30) {
      setWheelNav('next')
    }
  }, [touchDelta])


  return [wheelNav, setWheelNav]
}